import React, { useRef, useState } from 'react';
import styles from './PostDetail.module.css';
import NormalButton from 'components/NormalButton';
import ProfileImg from 'components/ProfileImg';
import CustomEditor from 'components/editor/CustomEditor';
import { Comment } from 'api/backend/comment';
import { timeForToday } from 'lib/utils';
import { selectUser } from 'store/authSlice';
import { useSelector } from 'react-redux';
import { removeComment } from 'api/backend/comment';
import { toast } from 'react-toastify';
import { Editor as TinyMCEEditor } from 'tinymce';
import { isMobile } from 'react-device-detect';

interface IProps {
  comments: Comment[];
  onRemove: () => void;
}

const CommentList = ({ comments, onRemove }: IProps) => {
  const [editId, setEditId] = useState<number | null>(null);
  const user = useSelector(selectUser);
  const editorRef = useRef<TinyMCEEditor>(null);

  const handleEdit = (id: number) => {
    setEditId(id);
  };

  const handleRemove = async (id: number) => {
    console.log('handleRemove', id);
    const res = await removeComment(id);
    if (res.result) {
      toast.info('댓글이 삭제되었습니다.');
      onRemove();
    }
  };

  return (
    <>
      {comments.map((c, idx) => (
        <div key={idx} className="flex flex-col gap-1 mb-2 border-b py-2 bg-white">
          <div className="flex justify-between">
            <div className="flex gap-2 items-center">
              <div className="w-10 h-10">
                <ProfileImg picurl={c.author.picUrl} borderWidth={0} />
              </div>
              <div className="flex flex-col">
                <div className="">{c.author.username}</div>
                <div className="text-xs text-[#777]">{timeForToday(c.createdAt)}</div>
              </div>
            </div>

            {c.author.id === user?.id && (
              <div className="flex items-center">
                <NormalButton onClick={() => handleEdit(c.id)}>수정</NormalButton>
                <NormalButton onClick={() => handleRemove(c.id)}>삭제</NormalButton>
              </div>
            )}
          </div>

          <div className="w-full border-0 border-red-400">
            {c.id === editId ? (
              <div className="ml-0 w-full">
                <CustomEditor ref={editorRef} minHeight={isMobile ? 200 : 400} initialValue={c.contents} onEditorChange={() => {}} />
              </div>
            ) : (
              <div className="text-sm mt-1">
                <div dangerouslySetInnerHTML={{ __html: c.contents }} className={styles.ul}></div>
              </div>
            )}
          </div>
        </div>
      ))}
    </>
  );
};

export default CommentList;
