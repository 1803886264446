import { useState, useEffect } from 'react';
import { ReactComponent as ProfileNoImg } from 'assets/images/profile-no-img.svg';
import { getImageProxyUrl } from 'lib/getImageProxyUrl';

interface ProfileImgProps {
  borderWidth: number;
  picurl?: string;
  padding?: number;
  borderColor?: string;
}
const ProfileImg = (props: ProfileImgProps) => {
  const { picurl, borderWidth, padding, borderColor } = props;
  const [imgLoadError, setImgLoadError] = useState(false);
  const [imgSrc, setImgSrc] = useState<string>();

  useEffect(() => {
    async function load() {
      if (!picurl) return;
      if (picurl.startsWith('http://')) {
        setImgSrc(getImageProxyUrl(picurl));
      } else {
        setImgSrc(picurl);
      }
    }
    load();
  }, [picurl]);

  return (
    <div
      className="bg-white rounded-full overflow-hidden w-full h-full border shrink-0 justify-center items-center"
      style={{
        padding: padding ? `${padding}rem` : '0',
        borderWidth: borderWidth ? `${borderWidth}px` : '0',
        borderColor: borderColor ? `${borderColor}` : '#707070',
      }}
    >
      {imgLoadError || !picurl ? (
        <ProfileNoImg fill="#555" className="p-0" />
      ) : (
        <img
          crossOrigin="anonymous"
          className="flex justify-center items-center w-full h-full object-cover"
          src={imgSrc}
          alt=""
          onError={() => setImgLoadError(true)}
        />
      )}
    </div>
  );
};

ProfileImg.defaultProps = {
  borderWidth: 1,
};

export default ProfileImg;
