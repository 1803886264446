import React from 'react';

const Menu4 = () => {
  return (
    <div className="min-h-[80vh]">
      <div>Menu4</div>
    </div>
  );
};

export default Menu4;
