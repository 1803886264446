import dateFormat from 'dateformat';

export const rand = (min: number, max: number) => {
  return Math.floor(Math.random() * (max - min + 1)) + min;
};

export const randName = () => {
  let text = '';
  let first =
    '김이박최정강조윤장임한오서신권황안송류전홍고문양손배조백허유남심노정하곽성차주우구신임나전민유진지엄채원천방공강현함변염양변여추노도소신석선설마주연방위표명기반왕모장남탁국여진구';
  let last =
    '가강건경고관광구규근기길나남노누다단달담대덕도동두라래로루리마만명무문미민바박백범별병보사산상새서석선설섭성세소솔수숙순숭슬승시신아안애엄여연영예오옥완요용우원월위유윤율으은의이익인일자잔장재전정제조종주준중지진찬창채천철초춘충치탐태택판하한해혁현형혜호홍화환회효훈휘희운모배부림봉혼황량린을비솜공면탁온디항후려균묵송욱휴언들견추걸삼열웅분변양출타흥겸곤번식란더손술반빈실직악람권복심헌엽학개평늘랑향울련';

  for (let i = 0; i < 1; i++) text += first.charAt(Math.floor(Math.random() * first.length));
  for (let i = 0; i < 2; i++) text += last.charAt(Math.floor(Math.random() * last.length));

  return text;
};

export const getCurrentLocalTimeString = () => {
  const today = new Date().toLocaleTimeString();
  return today;
};

export const setFullScreenCssProperty = () => {
  let vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty('--vh', `${vh}px`);
  window.addEventListener('resize', () => {
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
  });
};

export const getFileSize = (size: number): string => {
  if (size >= 1024 * 1024 * 1024) {
    return `${Math.floor(size / (1024 * 1024 * 1024))}GB`;
  }
  if (size >= 1024 * 1024) {
    return `${Math.floor(size / (1024 * 1024))}MB`;
  }
  if (size >= 1024) {
    return `${Math.floor(size / 1024)}KB`;
  }
  return `${size}B`;
};

export const getFileNameFromUrl = (url: string) => {
  const lastSlashIndex = url.lastIndexOf('/');
  const extractedString = url.substring(lastSlashIndex + 1);
  return extractedString;
};

export const getDateFormat = (date: string) => {
  return dateFormat(new Date(date), 'yyyy.m.d HH:MM:ss');
};

export const timeForToday = (value: string) => {
  const today = new Date();
  const timeValue = new Date(value);

  const betweenTime = Math.floor((today.getTime() - timeValue.getTime()) / 1000 / 60);
  if (betweenTime < 1) return '방금전';
  if (betweenTime < 60) {
    return `${betweenTime}분전`;
  }

  const betweenTimeHour = Math.floor(betweenTime / 60);
  if (betweenTimeHour < 24) {
    return `${betweenTimeHour}시간전`;
  }

  const betweenTimeDay = Math.floor(betweenTime / 60 / 24);
  if (betweenTimeDay < 20) {
    return `${betweenTimeDay}일전`;
  }

  return dateFormat(timeValue, 'yyyy.m.d');

  // return `${Math.floor(betweenTimeDay / 365)}년전`;
};
