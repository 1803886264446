import React from 'react';
import './App.css';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import Layout from 'components/Layout';
import TestPage from 'pages/TestPage';
import Redirect from 'pages/Redirect';
import Menu1 from 'pages/Menu1';
import Menu2 from 'pages/Menu2';
import Menu3 from 'pages/Menu3';
import Menu4 from 'pages/Menu4';
import More from 'pages/More';
import ErrorPage from 'pages/ErrorPage';
import PostList from 'pages/post/PostList';
import PostDetail from 'pages/post/PostDetail';
import PostWrite from 'pages/post/PostWrite';
import StartPage from 'pages/StartPage';
import { fcmSetting } from 'lib/fcm';

function App() {
  fcmSetting();

  const router = createBrowserRouter([
    {
      path: '/',
      element: <Layout />,
      errorElement: <ErrorPage />,
      children: [
        { index: true, element: <StartPage /> },
        {
          path: 'menu1',
          element: <Menu1 />,
          children: [
            { index: true, element: <PostList /> },
            { path: 'detail/:postId', element: <PostDetail /> },
            { path: 'write', element: <PostWrite /> },
            { path: 'edit/:postId', element: <PostWrite /> },
          ],
        },
        { path: 'menu2', element: <Menu2 /> },
        { path: 'menu3', element: <Menu3 /> },
        { path: 'menu4', element: <Menu4 /> },
        { path: 'more', element: <More /> },
      ],
    },

    { path: '/testpage', element: <TestPage /> },
    { path: '/redirect', element: <Redirect /> },
  ]);

  return <RouterProvider router={router} />;
}

export default React.memo(App);
