import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface IUtilState {
  homeSaveImageFunc: () => void;
}

const initialState: IUtilState = {
  homeSaveImageFunc: () => {},
};

export const utilSlice = createSlice({
  name: 'util',
  initialState,
  reducers: {
    setHomeSaveImageFunc: (state: IUtilState, action: PayloadAction<() => void>) => {
      state.homeSaveImageFunc = action.payload;
    },
    executeHomeSaveImageFunc: (state: IUtilState) => {
      state.homeSaveImageFunc();
    },
  },
});

export const { setHomeSaveImageFunc, executeHomeSaveImageFunc } = utilSlice.actions;

export default utilSlice.reducer;
