import React, { useRef } from 'react';
import CustomEditor from 'components/editor/CustomEditor';
import ProfileImg from 'components/ProfileImg';
import NormalButton from 'components/NormalButton';
import { IUser } from 'store/authSlice';
import { writeComment } from 'api/backend/comment';
import { Editor as TinyMCEEditor } from 'tinymce';
import { toast } from 'react-toastify';

interface IProps {
  postId: string;
  user: IUser;
  onSave: () => void;
}
const CommentWrite = ({ postId, user, onSave }: IProps) => {
  const editorRef = useRef<TinyMCEEditor>(null);

  const handleSave = async () => {
    if (!editorRef.current) return;
    await editorRef.current?.uploadImages();
    const testContents = editorRef.current.getContent();

    const res = await writeComment({ postId, contents: testContents });
    console.log('writeComment res', res);
    if (res.result) {
      editorRef.current.setContent('');
      toast.success('댓글이 등록되었습니다.');
      onSave();
    }
  };

  return (
    <div>
      <div className="flex">
        <div className="w-10 h-10">
          <ProfileImg picurl={user.picurl} />
        </div>
        <div className="ml-2 w-full">
          <CustomEditor ref={editorRef} minHeight={200} initialValue="" onEditorChange={() => {}} />
        </div>
      </div>
      <div className="flex w-full justify-end mt-2">
        <NormalButton onClick={handleSave}>댓글등록</NormalButton>
      </div>
    </div>
  );
};

export default CommentWrite;
