import { useInfiniteQuery } from '@tanstack/react-query';
import { getPostList } from 'api/backend/post';

interface IProps {
  take: number;
}

const useGetPostsIS = ({ take }: IProps) => {
  const fetchPosts = async ({ pageParam = 1 }) => {
    const data = await getPostList(pageParam, take);
    const nextPage = data.page !== data.pages ? data.page + 1 : undefined;
    return { result: data.results, nextPage };
  };

  return useInfiniteQuery(['posts'], fetchPosts, {
    getNextPageParam: (lastPage, pages) => {
      return lastPage.nextPage ?? undefined;
    },
  });
};

export default useGetPostsIS;
