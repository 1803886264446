import React from 'react';

const Menu3 = () => {
  return (
    <div className="min-h-[80vh]">
      <div>Menu3</div>
    </div>
  );
};

export default Menu3;
