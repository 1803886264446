import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from 'store/store';

export type SimpleUserInfo = {
  isInsider: boolean;
  bidOrEmail: string;
  username?: string;
  picurl?: string;
};

export interface IUsers {
  map: Map<string, SimpleUserInfo>;
}

const initialState: IUsers = {
  map: new Map(),
};

export const usersSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    addUser: (state: IUsers, action: PayloadAction<SimpleUserInfo>) => {
      state.map.set(action.payload.bidOrEmail, action.payload);
    },
  },
});

export const { addUser } = usersSlice.actions;
export const selectUsers = (state: RootState) => state.users.map;

export default usersSlice.reducer;
