import React, { Fragment, useEffect } from 'react';
import useGetPostsIS from 'hooks/useGetPostsIS';
import ProfileImg from 'components/ProfileImg';
import { useInView } from 'react-intersection-observer';
import { Post } from 'api/backend/post';
import { useNavigate } from 'react-router-dom';
import { timeForToday } from 'lib/utils';

export interface IProps {
  take: number;
  onTotal?: (total: number) => void;
}

const PostListInfiniteScroll = ({ take, onTotal }: IProps) => {
  const { ref, inView } = useInView({ threshold: 0 });
  const { data, isSuccess, isFetchingNextPage, hasNextPage, fetchNextPage } = useGetPostsIS({ take });
  const navigate = useNavigate();

  const handleOnClick = (id: number) => {
    navigate(`/menu1/detail/${id}`);
  };

  useEffect(() => {
    if (inView) {
      console.log('화면끝 보임');
      fetchNextPage();
    }
  }, [fetchNextPage, inView]);

  // useEffect(() => {
  //   if (posts) {
  //     onTotal(posts.total);
  //   }
  // }, [posts, onTotal]);

  return (
    <>
      <div className="w-full text-[#333] mt-1">
        {isSuccess &&
          data?.pages.map((group, i) => (
            <Fragment key={i}>
              {group.result.map((post: Post) => (
                <div
                  key={post.id}
                  onClick={() => handleOnClick(post.id)}
                  className="flex justify-between items-center h-fit py-2 w-full overflow-hidden border-t hover:bg-[#fcfcfc] cursor-pointer text-sm whitespace-nowrap"
                >
                  <div className="flex items-center shrink grow">
                    {/* <div className="px-2 text-center">{post.id}</div> */}
                    <div className="h-12 w-12 ml-2">
                      <ProfileImg borderWidth={1} borderColor={'#eee'} picurl={post.author.picUrl} />
                    </div>

                    <div className="ml-1">
                      <div className="flex items-center">
                        <div className="text-base text-ellipsis">{post.title}</div>
                        <div className="text-xs">({post.commentCnt})</div>
                      </div>
                      <div className="flex items-center">
                        <span className="text-center shrink-0 whitespace-nowrap">{timeForToday(post.createdAt)}</span>
                        <span className="ml-1">남</span>
                        <span className="ml-1">7Km</span>
                        <span className="ml-1 text-center whitespace-nowrap shrink-0">{post?.author.username}</span>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </Fragment>
          ))}
      </div>

      <div ref={ref} className="text-center h-1">
        {isFetchingNextPage ? '로딩중' : hasNextPage ? '로딩' : ''}
      </div>
    </>
  );
};

export default PostListInfiniteScroll;
