import React from 'react';

const More = () => {
  return (
    <div className="min-h-[80vh]">
      <div>More</div>
    </div>
  );
};

export default More;
