import axios from 'axios';
import { store } from 'store/store';
import { getConfig } from './config';

export interface CommentListRes {
  result: boolean;
  comments: Comment[];
}

export interface Comment {
  id: number;
  contents: string;
  createdAt: string;
  updatedAt: string;
  author: Author;
  parentId?: number;
}

export interface Author {
  id: number;
  username: string;
  picUrl: string;
  point: number;
}

export interface ICommentWrite {
  postId: string;
  contents: string;
}

export interface ICommentWriteRes {
  result: boolean;
  comment: Comment;
}

export interface ICommentRemoveRes {
  result: boolean;
  comment: Comment;
}

export const getCommentList = async (postId: string): Promise<CommentListRes> => {
  const url = `/api/comment/${postId}`;
  const config = getConfig(store.getState().auth.jwt);
  // console.log('commentList config', config);
  const res = await axios.get(url, config);
  return res.data;
};

export const writeComment = async (data: ICommentWrite): Promise<ICommentWriteRes> => {
  const url = `/api/comment/${data.postId}`;
  const config = getConfig(store.getState().auth.jwt);
  const res = await axios.post(url, { contents: data.contents }, config);
  return res.data;
};

export const removeComment = async (id: number): Promise<ICommentRemoveRes> => {
  console.log('게시물 삭제', id);
  const url = `/api/comment/${id}`;
  const config = getConfig(store.getState().auth.jwt);
  const res = await axios.delete(url, config);
  return res.data;
};
