import axios from 'axios';
import { store } from 'store/store';
import { getConfig } from './config';

export interface PostListRes {
  results: Post[];
  pageTotal: number;
  total: number;
  pages: number;
  page: number;
}

export interface Post {
  id: number;
  title: string;
  contents: string;
  commentCnt: number;
  likeCnt: number;
  createdAt: string;
  updateAt: string;
  author: Author;
  attachments: Attachment[];
  postLikes: IPostLike[];
}

export interface Author {
  id: number;
  username: string;
  picUrl: string;
  point: number;
}

export interface Attachment {
  id: string;
  fileUrl: string;
  fileSize: number;
  createAt: string;
}

export interface IPostWrite {
  title: string;
  contents: string;
  attachFile: IPostAttachFile;
}

export interface IPostWriteRes {
  result: boolean;
  post: Post;
}

export interface IPostAttachFile {
  url: string;
  size: number;
}

export interface IPostRemoveRes {
  result: boolean;
  msg?: string;
}

export interface IPostLike {
  authorId: number;
  isLike: boolean;
}

export interface IPostLikeUnlikeRes {
  result: boolean;
  msg?: string;
}

export const getPostList = async (pageNum: number = 1, cnt: number = 20): Promise<PostListRes> => {
  const url = `/api/post/list/${pageNum}/${cnt}`;
  const config = getConfig(store.getState().auth.jwt);
  // console.log('postList config', config);
  const res = await axios.get(url, config);
  return res.data;
};

export const getPostDetail = async (id: number): Promise<Post | null> => {
  const url = `/api/post/${id}`;
  const config = getConfig(store.getState().auth.jwt);
  const res = await axios.get(url, config);
  if (res.data.result === true) {
    // console.log('post detail res', res.data);
    return res.data.post;
  } else return null;
};

export const writePost = async (data: IPostWrite): Promise<IPostWriteRes> => {
  const url = '/api/post';
  const config = getConfig(store.getState().auth.jwt);
  const res = await axios.post(url, data, config);
  return res.data;
};

export const editPost = async (id: string, data: IPostWrite): Promise<IPostWriteRes> => {
  console.log('게시물 수정', id);
  const url = `/api/post/${id}`;
  const config = getConfig(store.getState().auth.jwt);
  const res = await axios.patch(url, data, config);
  return res.data;
};

export const removePost = async (id: number): Promise<IPostRemoveRes> => {
  console.log('게시물 삭제', id);
  const url = `/api/post/${id}`;
  const config = getConfig(store.getState().auth.jwt);
  const res = await axios.delete(url, config);
  return res.data;
};

export const likePost = async (id: string, isLike: boolean = true): Promise<IPostLikeUnlikeRes> => {
  // console.log(`게시물 좋아요/싫어요. id:${id}, isLike:${isLike}`);
  try {
    const url = `/api/post/${id}/${isLike ? 'like' : 'unlike'}`;
    const config = getConfig(store.getState().auth.jwt);
    const res = await axios.post(url, {}, config);
    return res.data;
  } catch (e) {
    console.error('게시물 좋아요/싫어요 처리 오류', e);
    return { result: false };
  }
};
