// redux/store.js
import { configureStore } from '@reduxjs/toolkit';
import utilReducer from './utilSlice';
import usersReducer from './usersSlice';
import authReducer from './authSlice';
import fcmReducer from './fcmSlice';

export const store = configureStore({
  reducer: {
    util: utilReducer,
    users: usersReducer,
    auth: authReducer,
    fcm: fcmReducer,
  },
  middleware: getDefaultMiddleware => getDefaultMiddleware({ serializableCheck: false }),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
