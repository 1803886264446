import axios from 'axios';
import { store } from 'store/store';
import { getConfig } from './config';

export const updateFcmToken = async (fcmToken: string) => {
  const url = '/api/user/update-fcm-token';

  if (!store.getState().auth.user) return;

  const userIdx = store.getState().auth.user?.id;
  const data = { userIdx, fcmToken };
  const config = getConfig(store.getState().auth.jwt);

  const res = await axios.post(url, data, config);
  console.log('updateFcmToken', res.data);
  return res.data;
};
