import React, { useEffect, useRef, useState } from 'react';
import ImageAttachment from './ImageAttachment';
import CustomEditor from 'components/editor/CustomEditor';
import { FieldValues, useForm } from 'react-hook-form';
import { Attachment, IPostAttachFile, getPostDetail, writePost, editPost, Post } from 'api/backend/post';
import { useNavigate, useParams } from 'react-router-dom';
import { uploadImage } from 'lib/uploadImage';
import { selectUser } from 'store/authSlice';
import { useSelector } from 'react-redux';
import { Editor as TinyMCEEditor } from 'tinymce';
import { isMobile } from 'react-device-detect';

const PostWrite = () => {
  let { postId } = useParams();
  const navigate = useNavigate();
  const [post, setPost] = useState<Post>();
  const [contents, setContents] = useState('');
  const [file, setFile] = useState<File>();
  const [attachments, setAttachments] = useState<Attachment[]>();
  const user = useSelector(selectUser);
  const editorRef = useRef<TinyMCEEditor>(null);

  console.log('write postId', postId);

  const {
    register,
    handleSubmit,
    setValue,
    formState: { isSubmitting, isSubmitted, errors },
  } = useForm({
    defaultValues: {
      title: '',
      // contents: '',
    },
  });

  useEffect(() => {
    const getPost = async () => {
      const data = await getPostDetail(Number(postId));
      console.log('detail res data', data);
      if (data) {
        setValue('title', data.title);
        // setValue('contents', data.contents);
        setPost(data);
        if (data.attachments) setAttachments(data.attachments);
      }
    };
    if (postId) getPost();
  }, [postId, setValue]);

  const onSubmit = async ({ title }: FieldValues) => {
    // await new Promise(r => setTimeout(r, 1000));
    console.log('input values', title);

    if (!editorRef.current) return;
    await editorRef.current?.uploadImages();
    const testContents = editorRef.current.getContent();

    let attachFile: IPostAttachFile = {
      url: '',
      size: 0,
    };

    // if (file) {
    //   const imgUploadRes = await uploadImage(file);
    //   console.log('imgUploadRes', imgUploadRes);
    //   if (imgUploadRes.result) attachFile = { url: imgUploadRes.location, size: imgUploadRes.size };
    // }

    let res;
    if (postId) {
      res = await editPost(postId, { title, contents: testContents, attachFile });
    } else {
      res = await writePost({ title, contents: testContents, attachFile });
    }
    console.log(res);
    if (res.result) navigate('/menu1');
  };

  const handleRemoveAttachImage = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    console.log('첨부 삭제');
  };

  return (
    <div className="w-full px-2 md:px-0">
      <div className="text-2xl mt-4 font-semibold">모두와 함께 할 때 더 즐거운 순간</div>
      <div className="text-sm text-[#777]">{user?.nickname}님의 솔직한 이야기를 적어주세요.</div>
      <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col mt-2">
        <label className="mt-4" htmlFor="title">
          제목
        </label>
        <input
          id="title"
          type="text"
          placeholder="제목을 입력해주세요."
          aria-invalid={isSubmitted ? (errors.title ? 'true' : 'false') : undefined}
          {...register('title', {
            required: '제목은 필수입니다.',
            minLength: {
              value: 5,
              message: '제목은 최소 5자리 이상입니다.',
            },
          })}
          className="mt-2 border rounded h-10 px-2 hover:border-blue-200"
        />
        {errors.title && (
          <small role="alert" className="text-red-400">
            {errors.title?.message}
          </small>
        )}

        <label htmlFor="contents" className="mt-4">
          내용
        </label>

        <CustomEditor
          ref={editorRef}
          minHeight={isMobile ? 200 : 400}
          initialValue={postId && post ? post.contents : ''}
          onEditorChange={newVal => {
            // setValue('contents', contents);
            setContents(newVal);
          }}
        />

        {/* <textarea
          id="contents"
          placeholder="내용을 입력해주세요."
          rows={10}
          aria-invalid={isSubmitted ? (errors.contents ? 'true' : 'false') : undefined}
          {...register('contents', {
            required: '내용은 필수입력입니다.',
            minLength: {
              value: 5,
              message: '내용은 최소 5자 이상입니다.',
            },
            // onChange: e => handleResizeHeight(e),
          })}
          className="mt-2 border rounded px-2 hover:border-blue-200"
        /> */}

        {/* <div className="mt-2">
          <Controller
            name="contents"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <CustomEditor
                initialValue={field.value}
                onEditorChange={contents => {
                  setValue('contents', contents);
                }}
                {...register('contents', {
                  required: '내용은 필수입력입니다.',
                  minLength: {
                    value: 10,
                    message: '내용이 너무 짧습니다.',
                  },
                })}
              />
            )}
          />
        </div> */}
        {/* {errors.contents && (
          <small role="alert" className="text-red-400">
            {errors.contents?.message}
          </small>
        )} */}

        {attachments && attachments.length > 0 && (
          <div className="mt-4">
            <div className="bg-[#555] w-fit px-4 mb-2 text-white rounded-full border border-[#555] text-sm">첨부파일</div>

            {attachments?.map(att => (
              <div key={att.id} className="relative w-fit h-fit">
                <img src={att.fileUrl} alt="img" key={att.id} className="rounded-[1rem] max-w-[20rem]" />
                <button
                  className=" absolute top-1 right-1 w-10 h-10 bg-red-300 hover:bg-[#777] flex justify-center items-center rounded-full border-2 border-[#fff] text-white"
                  onClick={handleRemoveAttachImage}
                >
                  삭제
                </button>
              </div>
            ))}
          </div>
        )}

        {/* <div className="mt-4 mb-2">이미지 첨부</div>
        <ImageAttachment setFile={setFile} /> */}

        <div className="flex my-8 h-9 gap-1 w-full justify-center">
          <button className=" bg-[#555] hover:bg-[#333] rounded disabled:bg-gray-500 text-white px-8" onClick={() => navigate('/menu1')}>
            취소
          </button>
          <button type="submit" className=" bg-[#539] hover:bg-[#416] rounded disabled:bg-gray-500 text-white px-8" disabled={isSubmitting}>
            전송
          </button>
        </div>
      </form>
    </div>
  );
};

export default PostWrite;
