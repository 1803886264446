import React from 'react';
import { Link } from 'react-router-dom';

interface IProps {
  to: string;
  isSelect?: boolean;
  isMobileFill?: boolean;
  children: React.ReactNode;
}

const LinkButton = ({ to, children, isSelect, isMobileFill }: IProps) => {
  return (
    <Link
      to={to}
      className={`flex ${isMobileFill && 'w-full'} md:w-[5rem] h-9 px-2 border rounded justify-center items-center ${
        isSelect ? 'bg-[#777] hover:bg-[#555]' : 'bg-[#777] hover:bg-[#555]'
      }  text-white`}
    >
      {children}
    </Link>
  );
};

export default LinkButton;
