import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from 'store/store';

export interface IUser {
  userid: string;
  nickname: string;
  picurl: string;
  id: number;
}
export interface IState {
  jwt: string;
  user: IUser | null;
}

const initialState: IState = {
  jwt: '',
  user: null,
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setJwt: (state: IState, action: PayloadAction<string>) => {
      state.jwt = action.payload;
    },
    setUser: (state: IState, action: PayloadAction<IUser | null>) => {
      state.user = action.payload;
    },
  },
});

export const { setJwt, setUser } = authSlice.actions;
export const selectJwt = (state: RootState) => state.auth.jwt;
export const selectUser = (state: RootState) => state.auth.user;

export default authSlice.reducer;
