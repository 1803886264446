import { useEffect } from 'react';
import { useCurrentLocation } from 'hooks/useCurrentPosition';
import { updateLocation } from 'api/backend/updateLocation';
import { useSelector } from 'react-redux';
import { selectUser } from 'store/authSlice';

const LocationUpdate = () => {
  const { location } = useCurrentLocation();
  const user = useSelector(selectUser);

  useEffect(() => {
    if (location && user) {
      updateLocation(user.id, location.latitude, location.longitude);
    }
  }, [location, user]);

  return null;
  // (
  //   <div className="flex font-sm gap-1 outline">
  //     <div>lat:{location?.latitude}</div>
  //     <div>lng:{location?.longitude}</div>
  //   </div>
  // );
};

export default LocationUpdate;
