export const pc_config = {
  iceServers: [
    {
      // urls: 'stun:stun.l.google.com:19302',
      urls: ['turn:h.jjcom.co.kr:3478?transport=udp'],
      username: 'appolon',
      credential: 'jjcom1!',
    },
  ],
};
