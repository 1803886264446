import axios from 'axios';
import { store } from 'store/store';
import { getConfig } from './config';

export const updateLocation = async (userIdx: number, lat: number, lng: number) => {
  const url = '/api/user/update-location';
  const data = { userIdx, lat: lat, lng: lng };
  const config = getConfig(store.getState().auth.jwt);

  const res = await axios.post(url, data, config);
  console.log('updateLocation', res.data);
  return res.data;
};
