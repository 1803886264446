import React from 'react';
import LinkButton from 'components/LinkButton';
import PostListInfiniteScroll from 'pages/post/PostListInfiniteScroll';
import { selectJwt } from 'store/authSlice';
import { useSelector } from 'react-redux';

const PostList = () => {
  const jwt = useSelector(selectJwt);

  return (
    <div className="bg-white w-full">
      <div className="w-full flex gap-1 justify-around md:justify-center items-center mt-1">
        {jwt && (
          <>
            <LinkButton to="/menu1/write">Write</LinkButton>
            <div className="items-center h-4 w-[2px] mx-1 bg-[#eee]"></div>
          </>
        )}

        <LinkButton to="/menu1/list=all" isMobileFill={true}>
          TOT
        </LinkButton>
        <LinkButton to="/menu1/list=1" isMobileFill={true}>
          JY
        </LinkButton>
        <LinkButton to="/menu1/list=2" isMobileFill={true}>
          DN
        </LinkButton>
        <LinkButton to="/menu1/list=3" isMobileFill={true}>
          NE
        </LinkButton>
        <LinkButton to="/menu1/list=my" isMobileFill={true}>
          MY
        </LinkButton>
      </div>

      <div className="mt-0">
        <PostListInfiniteScroll take={50} />
      </div>
    </div>
  );
};

export default PostList;
