import { useState, useEffect, SetStateAction } from 'react';

export interface ILocation {
  latitude: number;
  longitude: number;
}

const defaultOptions = {
  enableHighAccuracy: true,
  timeout: 1000 * 60 * 1, // 1 min (1000 ms * 60 sec * 1 minute = 60 000ms)
  maximumAge: 1000 * 3600 * 24, // 24 hour
};

export const useCurrentLocation = (options = defaultOptions) => {
  const [location, setLocation] = useState<ILocation>();
  const [error, setError] = useState<string>();

  const handleSuccess = (pos: { coords: { latitude: any; longitude: any } }) => {
    const { latitude, longitude } = pos.coords;
    setLocation({ latitude, longitude });
  };

  const handleError = (error: { message: SetStateAction<string | undefined> }) => {
    setError(error.message);
  };

  useEffect(() => {
    const { geolocation } = navigator;

    if (!geolocation) {
      setError('Geolocation is not supported.');
      return;
    }

    geolocation.getCurrentPosition(handleSuccess, handleError, options);
  }, [options]);

  return { location, error };
};
