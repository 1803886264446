import React, { useRef } from 'react';
import { pc_config } from 'config/pc_config';
import { randName } from 'lib/utils';
import { io, Socket } from 'socket.io-client';

const opts = {
  path: '/ex-webrtc',
  transports: ['websocket'],
};

const Menu2 = () => {
  const socketRef = useRef<Socket>();
  const pcRef = useRef<RTCPeerConnection>();
  const localVideoRef = useRef<HTMLVideoElement>(null);
  const remoteVideoRef = useRef<HTMLVideoElement>(null);

  const setVideoTracks = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({
        video: true,
        audio: true,
      });

      if (localVideoRef.current) localVideoRef.current.srcObject = stream;
      if (!(pcRef.current && socketRef.current)) return;

      stream.getTracks().forEach(track => {
        if (!pcRef.current) return;
        pcRef.current.addTrack(track, stream);
      });

      pcRef.current.onicecandidate = e => {
        if (e.candidate) {
          if (!socketRef.current) return;
          console.log('emit candidate');
          socketRef.current.emit('candidate', e.candidate);
        }
      };

      pcRef.current.oniceconnectionstatechange = e => {
        console.log('pc.oniceconnectionstatechange');
      };

      pcRef.current.ontrack = ev => {
        console.log('pc.ontrack. add remotetrack success');
        if (remoteVideoRef.current) {
          remoteVideoRef.current.srcObject = ev.streams[0];
        }
      };
    } catch (e) {
      console.error(e);
    }
  };

  const createOffer = async () => {
    if (!(pcRef.current && socketRef.current)) return;
    try {
      console.log('pc.createOffer call');
      const sdp = await pcRef.current.createOffer({
        offerToReceiveAudio: true,
        offerToReceiveVideo: true,
      });
      console.log('pc.createOffer success');

      console.log('createOffer pc.setLocalDescription call');
      await pcRef.current.setLocalDescription(new RTCSessionDescription(sdp));
      console.log('createOffer pc.setLocalDescription success');

      console.log('createOffer emit offer');
      socketRef.current.emit('offer', sdp);
    } catch (e) {
      console.error('createOffer error', e);
    }
  };

  const createAnswer = async (sdp: RTCSessionDescription) => {
    if (!(pcRef.current && socketRef.current)) return;
    try {
      console.log('createAnswer pc.setRemoteDescription call');
      await pcRef.current.setRemoteDescription(new RTCSessionDescription(sdp));
      console.log('createAnswer pc.setRemoteDescription success');

      console.log('pc.createAnswer call');
      const mySdp = await pcRef.current.createAnswer({
        offerToReceiveVideo: true,
        offerToReceiveAudio: true,
      });
      console.log('pc.createAnswer success');

      console.log('pc.setLocalDescription call');
      await pcRef.current.setLocalDescription(new RTCSessionDescription(mySdp));
      console.log('pc.setLocalDescription success');

      console.log('emit answer');
      socketRef.current.emit('answer', mySdp);
    } catch (e) {
      console.error('createAnswer error', e);
    }
  };

  const handleStart = async () => {
    console.log('process.env.NODE_ENV', process.env.NODE_ENV);
    const SOCKET_SERVER_URL =
      process.env.NODE_ENV === 'development'
        ? 'http://localhost:4001'
        : `${window.location.protocol}//${window.location.hostname}${window.location.port}`;

    console.log('SOCKET_SERVER_URL', SOCKET_SERVER_URL);

    socketRef.current = io(SOCKET_SERVER_URL, opts);
    pcRef.current = new RTCPeerConnection(pc_config);

    await setVideoTracks();

    socketRef.current.on('all_users', (allUsers: Array<{ id: string }>) => {
      console.log('all_users', allUsers);
      if (allUsers.length > 0) {
        createOffer();
      }
    });

    socketRef.current.on('getOffer', (sdp: RTCSessionDescription) => {
      console.log('on getOffer. call createAnswer');
      createAnswer(sdp);
    });

    socketRef.current.on('getAnswer', (sdp: RTCSessionDescription) => {
      console.log('on getAnswer');
      if (!pcRef.current) return;
      console.log('getAnswer pc.setRemoteDescription call');
      pcRef.current.setRemoteDescription(new RTCSessionDescription(sdp));
      console.log('getAnswer pc.setRemoteDescription success');
    });

    socketRef.current.on('getCandidate', async (candidate: RTCIceCandidateInit) => {
      if (!pcRef.current) return;
      console.log('on getCandidate. pc.addIceCandidate call');
      await pcRef.current.addIceCandidate(new RTCIceCandidate(candidate));
      console.log('on getCandidate. pc.addIceCandidte success');
    });

    socketRef.current.on('room_full', () => {
      console.log('room_full');
      socketRef.current?.disconnect();
    });

    const name = randName();
    console.log('name', name);
    console.log('emit join_room');
    socketRef.current.emit('join_room', { room: '1234', email: name });

    return () => {
      if (socketRef.current) {
        socketRef.current.disconnect();
      }
      if (pcRef.current) {
        pcRef.current.close();
      }
    };
  };

  return (
    <div>
      <button className="h-10 bg-blue-300 px-4 border m-1" onClick={handleStart}>
        start
      </button>
      <div className="flex flex-col px-1">
        <video id="localvideo" className="w-full mt-4 bg-black aspect-video max-h-[40vh]" muted ref={localVideoRef} autoPlay />
        <video id="remotevideo" className="w-full mt-4 bg-black aspect-video max-h-[40vh]" ref={remoteVideoRef} autoPlay />
      </div>
    </div>
  );
};

export default Menu2;
