import React, { MutableRefObject, forwardRef, useEffect, useState } from 'react';
import { Editor } from '@tinymce/tinymce-react';
import { Editor as TinyMCEEditor } from 'tinymce';
import { image_upload_handler } from 'lib/imageUploadHandler';

interface IProps {
  initialValue: string;
  onEditorChange: (value: string) => void;
  minHeight?: number;
  isComment?: boolean;
}

const CustomEditor = forwardRef<TinyMCEEditor, IProps>((props, editorRef) => {
  const { initialValue, onEditorChange, minHeight, isComment } = props;
  const [url, setUrl] = useState<string>('');

  useEffect(() => {
    const url = `${window.location.protocol}//${window.location.hostname}${window.location.port ? ':' + window.location.port : ''}`;

    // console.log('editor scriptSrc', `${url}/tinymce/tinymce.min.js`);
    setUrl(url);
  }, []);

  return (
    <Editor
      tinymceScriptSrc={`${url}/tinymce/tinymce.min.js`}
      id="tinyEditor"
      initialValue={initialValue}
      onInit={(evt, editor) => {
        if (editorRef) {
          return ((editorRef as MutableRefObject<TinyMCEEditor>).current = editor);
        }
      }}
      init={{
        width: '100%',
        min_height: minHeight,
        statusbar: false,
        menubar: false,
        language: 'ko_KR',
        language_url: '/assets/TinyMCE/langs/ko_KR.js',
        skin_url: '/assets/TinyMCE/skins/default',
        image_uploadtab: false,
        file_picker_types: 'image',
        file_picker_callback: (callback, value, meta) => {
          console.log('filepickercallback', value);
        },
        quickbars_insert_toolbar: false,
        plugins: 'autoresize searchreplace importcss image link media template table anchor advlist lists quickbars emoticons image',
        toolbar: !isComment
          ? 'undo redo | fontfamily fontsize | forecolor backcolor | bold italic underline strikethrough | quickimage table link |  alignleft aligncenter alignright alignjustify | numlist bullist removeformat | emoticons searchreplace'
          : 'undo redo fontfamily fontsize forecolor backcolor bold italic underline strikethrough quickimage table link alignleft aligncenter alignright alignjustify numlist bullist removeformat emoticons searchreplace',
        // images_upload_url: `${process.env.REACT_APP_FILE_SERVER_URL}/files/upload/single_file`,
        automatic_uploads: false,
        relative_urls: false,
        remove_script_host: false,
        images_upload_handler: image_upload_handler,
        setup: function (ed) {},

        mobile: {
          theme: 'silver',
          toolbar_mode: 'floating',
          toolbar: !isComment
            ? 'undo redo | forecolor backcolor | bold italic underline strikethrough | quickimage table link |  alignleft aligncenter alignright alignjustify | numlist bullist removeformat'
            : 'quickimage link',
        },
      }}
      onEditorChange={onEditorChange}
    />
  );
});

CustomEditor.defaultProps = {
  minHeight: 400,
  isComment: false,
};

CustomEditor.displayName = 'CustomEditor';
export default CustomEditor;
