import * as jose from 'jose';
interface IProps {
  userid: string;
  nickname: string;
  picurl: string;
  id: number;
}

export const parseToken = async (token: string): Promise<IProps> => {
  try {
    const secretKey = new TextEncoder().encode(process.env.REACT_APP_ACCESS_TOKEN_SECRET);
    const { payload } = await jose.jwtVerify(token, secretKey);
    // console.log('payload', payload);
    const result = {
      id: payload.id as number,
      userid: payload.userid as string,
      nickname: payload.nickname as string,
      picurl: payload.picurl as string,
    };
    return result;
  } catch (e) {
    return { userid: '', nickname: '', picurl: '', id: 0 };
  }
};

export const makeToken = async ({ id, userid, nickname, picurl }: IProps): Promise<string> => {
  const secretKey = new TextEncoder().encode(process.env.REACT_APP_ACCESS_TOKEN_SECRET);
  const token = await new jose.SignJWT({ id, userid, nickname, picurl }).setProtectedHeader({ typ: 'JWT', alg: 'HS256' }).sign(secretKey);
  return token;
};
