import { initializeApp } from 'firebase/app';
import { getMessaging, getToken, onMessage } from 'firebase/messaging';
import { addFcmMessage, setFcmToken } from 'store/fcmSlice';
import { store } from 'store/store';

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

console.log('filebaseConfig', firebaseConfig);

export const firebaseApp = initializeApp(firebaseConfig);
export const messaging = getMessaging(firebaseApp);

export const fcmSetting = async () => {
  console.log('fcmSetting start');

  const permission = await Notification.requestPermission();
  if (permission !== 'granted') return;

  try {
    const currentToken = await getToken(messaging, { vapidKey: process.env.REACT_APP_FIREBASE_VAPID_KEY });
    if (currentToken) {
      console.log('currentToken', currentToken);
      store.dispatch(setFcmToken(currentToken));
    } else {
      console.log('No registration token available. Request permission to generate one.');
    }
  } catch (err) {
    console.error('fcmSetting getToken error', err);
  }

  onMessage(messaging, payload => {
    console.log('Message received. ', payload);
    // toast.info(payload.notification.body);
    if (payload.notification) {
      store.dispatch(
        addFcmMessage({
          title: payload.notification.title as string,
          body: payload.notification.body as string,
          time: new Date().toLocaleTimeString(),
        }),
      );
    }
  });
};
