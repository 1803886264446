import PWAInstallButton from 'components/PWAInstallButton';
import React from 'react';

const StartPage = () => {
  return (
    <div className="flex flex-col w-full h-full min-h-[90vh] grow px-2">
      <div className="flex justify-end mt-0 w-full">
        <PWAInstallButton />
      </div>

      <div className="mt-4 bg-[#555] w-fit px-4 mb-2 text-white rounded-full border border-[#555] text-sm">최근 등록된 게시물</div>

      <div className="mt-[20rem] bg-[#555] w-fit px-4 mb-2 text-white rounded-full border border-[#555] text-sm">최근 로그인 회원</div>
    </div>
  );
};

export default StartPage;
