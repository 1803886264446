import React from 'react';

interface IProps {
  onClick: () => void;
  isSelect?: boolean;
  isMobileFill?: boolean;
  children: React.ReactNode;
}

const NormalButton = ({ onClick, children, isSelect, isMobileFill }: IProps) => {
  return (
    <button
      className={`flex ${isMobileFill && 'w-full'} md:w-[5rem] h-9 px-2 border rounded justify-center items-center ${
        isSelect ? 'bg-[#777] hover:bg-[#555]' : 'bg-[#777] hover:bg-[#555]'
      }  text-white`}
      onClick={onClick}
    >
      {children}
    </button>
  );
};

export default NormalButton;
