import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from 'store/store';

export interface Message {
  title: string;
  body: string;
  time: string;
}

interface State {
  msgs: Message[];
  token: string;
}

const initialState: State = {
  msgs: [],
  token: '',
};

export const fcmSlice = createSlice({
  name: 'fcm',
  initialState,
  reducers: {
    addFcmMessage: (state: State, action: PayloadAction<Message>) => {
      state.msgs.push(action.payload);
    },
    setFcmToken: (state: State, action: PayloadAction<string>) => {
      state.token = action.payload;
    },
  },
});

export const { addFcmMessage, setFcmToken } = fcmSlice.actions;

export const selectFcmMessages = (state: RootState) => state.fcm.msgs;
export const selectFcmToken = (state: RootState) => state.fcm.token;

export default fcmSlice.reducer;
