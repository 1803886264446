import styles from './PostDetail.module.css';
import React, { useCallback, useEffect, useState } from 'react';
import ProfileImg from 'components/ProfileImg';
import LinkButton from 'components/LinkButton';
import NormalButton from 'components/NormalButton';
import CommentWrite from './CommentWrite';
import CommentList from './CommentList';
import Sympathy from 'components/Sympathy';
import { useParams, useNavigate } from 'react-router-dom';
import { Attachment, Post, getPostDetail, likePost, removePost, IPostLikeUnlikeRes } from 'api/backend/post';
import { getCommentList, Comment } from 'api/backend/comment';
import { getDateFormat } from 'lib/utils';
import { selectUser } from 'store/authSlice';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';

const PostDetail = () => {
  let { postId } = useParams();
  const [post, setPost] = useState<Post | null>(null);
  const [comments, setComments] = useState<Comment[]>([]);
  const [attachments, setAttachments] = useState<Attachment[]>();
  const [isMyLike, setIsMyLike] = useState<boolean | undefined>();
  const navigate = useNavigate();
  const user = useSelector(selectUser);

  const getData = useCallback(async () => {
    const data = await getPostDetail(Number(postId));
    console.log('detail res data', data);
    if (data) setPost(data);
    if (data?.attachments) setAttachments(data.attachments);
    const comm = await getCommentList(postId as string);
    if (comm.result) setComments(comm.comments);
  }, [postId]);

  useEffect(() => {
    getData();
  }, [getData, postId]);

  useEffect(() => {
    if (!post || !user) return;

    const like = post.postLikes.filter(like => like.authorId === user.id);
    console.log('like', like);
    if (like.length) {
      setIsMyLike(like[0].isLike);
    } else {
      setIsMyLike(undefined);
    }
  }, [post, user]);

  const handleEdit = () => {
    if (!post) return;
    navigate(`/menu1/edit/${post.id}`);
  };

  const handleRemove = async () => {
    console.log('remove post', post?.id);
    if (!post) return;
    const res = await removePost(post.id);
    console.log('post delete res', res);
    if (res.result) navigate('/menu1');
  };

  const handleCommentUpdate = async () => {
    const comm = await getCommentList(postId as string);
    if (comm.result) setComments(comm.comments);
  };

  const handleSympathyChanged = async (value: number) => {
    // console.log('handleSympathyChanged', value);
    let res: IPostLikeUnlikeRes = await likePost(postId as string, value > 0);
    console.log('좋아요/싫어요 처리 결과', res);
    if (res.result) {
      getData();
    } else {
      toast.warn(res.msg);
    }
  };

  if (!post) return <></>;

  return (
    <div className="w-full flex flex-col">
      <div className="h-12 w-full text-white flex items-center px-0">
        <LinkButton to="/menu1">글 목록</LinkButton>

        {user && user?.id === post.author.id && (
          <>
            <NormalButton onClick={handleEdit}>수정</NormalButton>
            <NormalButton onClick={handleRemove}>삭제</NormalButton>
          </>
        )}
      </div>
      <div className="p-2 w-full">
        <div className="relative flex h-10 mt-4">
          <div className="w-10 h-10">
            <ProfileImg picurl={post.author.picUrl} borderWidth={0} />
          </div>
          <div className="flex flex-col text-sm ml-2 text-black">
            <div className="flex items-center">
              <div className="">{post?.author.username}</div>
              <div className="bg-[#eee] w-fit rounded px-2">인삿말 듣기</div>
            </div>
            <div className="">좋아요: 587</div>
          </div>
        </div>

        <div className="mt-4 w-full">
          <div className="font-bold text-xl">{post?.title}</div>
          {/* <div className="mt-4 whitespace-pre-line">{post?.contents}</div> */}
          <div className="mt-4 text-[#000] leading-10">
            <div dangerouslySetInnerHTML={{ __html: post.contents }} className={styles.ul}></div>
          </div>

          {attachments && attachments.length > 0 && (
            <div className="mt-4">
              {/* <div className="bg-[#555] w-fit px-4 mb-2 text-white rounded-full border border-[#555] text-sm">첨부파일</div> */}
              {attachments?.map(att => (
                <img src={att.fileUrl} alt="img" key={att.id} className="rounded-[1rem] w-full max-w-[40rem]" />
              ))}
            </div>
          )}

          <div className="w-full flex justify-start">
            <div className="mt-4 bg-[#555] w-fit px-4 mb-2 text-white rounded-full border border-[#555] text-sm">{getDateFormat(post.createdAt)}</div>
          </div>

          <div className="flex justify-end items-center mt-4">
            <Sympathy onChange={handleSympathyChanged} initValue={post.likeCnt} isSmall={false} isMyLike={isMyLike} />
          </div>

          <div className="flex flex-row w-full gap-1 mt-12 text-sm items-center">
            <div className="">댓글</div>
            <div className="flex border w-6 h-6 text-center rounded-full justify-center items-center">{post?.commentCnt}</div>
          </div>

          {user && postId && (
            <div className="mt-4">
              <CommentWrite user={user} postId={postId} onSave={handleCommentUpdate} />
            </div>
          )}

          <div className="flex flex-col mt-4">
            <CommentList comments={comments} onRemove={handleCommentUpdate} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default PostDetail;
