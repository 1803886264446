import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import UserLogin from './UserLogin';
// import { useMediaQuery } from 'react-responsive';

const linkStyle = 'flex h-full justify-center items-center px-1 hover:text-[#555]';

const Header = () => {
  const location = useLocation();
  const { pathname } = location;
  // const isMd = useMediaQuery({ query: '(min-width: 768px)' });

  return (
    <div className="w-full h-full flex mx-auto text-black bg-white justify-between z-50">
      <Link to="/" className={`ml-0 ${linkStyle} text-black}`}>
        <div className="rounded-full w-10 h-10 bg-[#777] flex justify-center items-center text-white">L</div>
      </Link>

      <Link to="/menu1" className={`${linkStyle} ${pathname.startsWith('/menu1') ? 'text-red-600' : 'text-black'}`}>
        <div className="">Menu1</div>
      </Link>

      <Link to="/menu2" className={`${linkStyle} ${pathname === '/menu2' ? 'text-red-600' : 'text-black'}`}>
        <div className="">Menu2</div>
      </Link>

      <Link to="/menu3" className={`${linkStyle} ${pathname === '/menu3' ? 'text-red-600' : 'text-black'}`}>
        <div className="">Menu3</div>
      </Link>

      <Link to="/menu4" className={`${linkStyle} ${pathname === '/menu4' ? 'text-red-600' : 'text-black'}`}>
        <div className="">Menu4</div>
      </Link>

      <Link to="/more" className={`${linkStyle} ${pathname === '/more' ? 'text-red-600' : 'text-black'}`}>
        <div className="">More</div>
      </Link>

      <div className="hidden"></div>

      <div className="className={`h-full flex justify-center items-center px-1 shrink-0">
        <UserLogin />
      </div>
    </div>
  );
};

export default Header;
