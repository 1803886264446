import React from 'react';
import Header from './Header';
import { Outlet } from 'react-router-dom';

const Layout = () => {
  return (
    <div className="flex flex-col justify-center h-full">
      <div className="h-16 fixed top-0 left-0 right-0 w-full mx-auto border-b z-10">
        <div className="h-full mx-auto md:w-[50rem] lg:w-[60rem] xl:w-[70rem]">
          <Header />
        </div>
      </div>
      <div className="mt-16 mx-auto flex-1 w-full md:w-[50rem] lg:w-[60rem] xl:w-[70rem] z-0">
        <Outlet />
      </div>

      <div className="mt-auto h-10 w-full border-t">
        <div className="w-full md:w-[50rem] lg:w-[60rem] xl:w-[70rem] bg-[#ddd] flex mx-auto">
          <div className="m-auto">Footer</div>
        </div>
      </div>
    </div>
  );
};

export default Layout;
