import React, { useEffect } from 'react';
import ProfileImg from './ProfileImg';
import LocationUpdate from './LocationUpdate';
import { useDispatch, useSelector } from 'react-redux';
import { setUser, setJwt, selectUser } from 'store/authSlice';
import { Cookies } from 'react-cookie';
import { parseToken } from 'lib/tokenUtils';
import { useNavigate } from 'react-router-dom';
import { selectFcmToken } from 'store/fcmSlice';
import { updateFcmToken } from 'api/backend/updateFcmToken';

const UserLogin = () => {
  const user = useSelector(selectUser);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const fcmToken = useSelector(selectFcmToken);

  const signIn = (provider: string) => {
    navigate('/redirect', { state: { url: '/auth/kakao' } });
  };

  useEffect(() => {
    const cookies = new Cookies();
    const token = cookies.get('jwt');

    const parse = async () => {
      const verified = await parseToken(token);
      // console.log('verified', verified);
      dispatch(setUser(verified));
      dispatch(setJwt(token));
    };

    if (token) parse();
  }, [dispatch]);

  useEffect(() => {
    if (user && fcmToken) {
      updateFcmToken(fcmToken);
    }
  }, [fcmToken, user]);

  const handleRemoveCookie = () => {
    const cookies = new Cookies();
    cookies.remove('jwt');
    dispatch(setUser(null));
    dispatch(setJwt(''));
    window.location.replace('/');
  };

  return (
    <div className="p-0">
      {user ? (
        <>
          <div className="flex items-center w-full h-fit justify-center">
            <div className="w-10 h-10 shrink-0 cursor-pointer" onClick={handleRemoveCookie}>
              <ProfileImg borderWidth={1} picurl={user.picurl} />
            </div>
            {/* <span className="mx-2">{user.nickname}</span> */}

            {/* <button onClick={handleRemoveCookie} className="ml-1 w-fit h-10 px-6 rounded-full bg-[#757] text-white text-sm border hover:bg-[#535]">
              로그아웃
            </button> */}
          </div>
        </>
      ) : (
        <div className="flex w-full h-full px-0 justify-center items-center">
          <button onClick={() => signIn('kakao')} className="w-fit h-10 px-6 rounded-full bg-[#757] text-white text-sm border hover:bg-[#535]">
            로그인
          </button>
        </div>
      )}
      <LocationUpdate />
    </div>
  );
};

export default UserLogin;
