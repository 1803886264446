import React, { useState } from 'react';

const TestPage = () => {
  const [isFull, setIsFull] = useState(false);
  const client_view = 'w-[200px] h-[150px] bg-white rounded border-2 border-gray-400 m-1 shadow-lg shrink-0';

  const handleFull = () => {
    setIsFull(!isFull);
  };

  return (
    <div className="w-full h-full bg-slate-700 text-white flex flex-col overflow-y-hidden p-0">
      <div className="relative h-full flex flex-col mb-20 bg-gray-400 border-4 border-red-300">
        <div className="grow h-1 bg-gray-700 flex flex-col justify-center items-center border-4 border-green-300">
          <div className="w-full h-full bg-amber-100 flex justify-center items-center">
            <div className="bg-gray-400">BigVideo</div>
          </div>
        </div>
        <div className="grow-0 bg-gray-500 w-full overflow-x-auto overflow-y-hidden" hidden={isFull}>
          <div className="flex justify-start items-center p-1 h-full">
            <div className={client_view} />
            <div className={client_view} />
            <div className={client_view} />
            <div className={client_view} />
            <div className={client_view} />
            <div className={client_view} />
            <div className={client_view} />
            <div className={client_view} />
          </div>
        </div>
      </div>
      <div className="fixed bottom-0 w-full grow-0 bg-[#333] h-20 flex justify-center items-center min-h-fit">
        <button className="w-[4rem] h-[4rem] rounded border bg-blue-400" onClick={handleFull}>
          Full
        </button>
      </div>
    </div>
  );
};

export default TestPage;
