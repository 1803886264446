import React, { useState } from 'react';

interface IProps {
  onChange: (value: number) => void;
  initValue: number;
  isSmall: boolean;
  isMyLike: boolean | undefined;
}

const Sympathy = (props: IProps) => {
  const { onChange, initValue, isSmall, isMyLike } = props;
  // const [value, setValue] = useState(initValue);

  const handleOnChange = (newValue: number) => {
    // setValue(newValue);
    onChange(newValue);
  };

  return (
    <div className="flex">
      <button
        className={`${isSmall ? 'h-7 w-7' : 'h-9 w-9'} border bg-white rounded-l flex justify-center items-center`}
        onClick={() => handleOnChange(-1)}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth="1.5"
          stroke="currentColor"
          aria-hidden="true"
          className={`${isMyLike === false ? 'text-[#f00]' : 'text-[#000]'} hover:text-[#f00] group-hover:text-[#f00] h-4 w-4`}
        >
          <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5"></path>
        </svg>
      </button>
      <div className={`${isSmall ? 'h-7' : 'h-9'}h-9 bg-white flex justify-center items-center px-2 border-y min-w-[3rem]`}>{initValue}</div>
      <button
        className={`${isSmall ? 'h-7 w-7' : 'h-9 w-9'} border bg-white rounded-r flex justify-center items-center`}
        onClick={() => handleOnChange(1)}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth="1.5"
          stroke="currentColor"
          aria-hidden="true"
          className={`${isMyLike ? 'text-[#09f] font-bold' : 'text-[#000]'} hover:text-[#09f] group-hover:text-[#09f] h-4 w-4`}
        >
          <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 15.75l7.5-7.5 7.5 7.5"></path>
        </svg>
      </button>
    </div>
  );
};

export default Sympathy;
